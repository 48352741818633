@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import 'animations';
body * {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  outline: 0;
}

body {
  width: 100%;
  height: 100%;
  list-style: none; 
  font-family: 'Castledown', sans-serif;
}

#wrapper { 
  width: 100%; 
  overflow: hidden;
}

.container { 
  text-align: left;
  max-width: 1140px; 
  margin: 0 auto; 
  padding-left: 15px!important;
  padding-right: 15px!important;
  width: 100%; 
  box-sizing:border-box; 
  -moz-box-sizing:border-box; 
  -webkit-box-sizing:border-box; 
  padding: 0;
}

a:focus{
  text-decoration: none;
}

/******MIXIN******/
@mixin prefixonprop($prop, $val...) {
    -webkit-#{$prop}: $val;
    -moz-#{$prop}: $val;
    -o-#{$prop}: $val;
    -ms-#{$prop}: $val;
    #{$prop}: $val;
}

@mixin prefixonvalue($prop, $val...) {
    #{$prop}: -webkit-#{$val};
    #{$prop}: -moz-#{$val};
    #{$prop}: -o-#{$val};
    #{$prop}: -ms-#{$val};
    #{$prop}: $val;
}

@mixin block { 
  margin: 0 auto; 
  width: 100%; 
  display: block;
}

@mixin flex_box{
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin clear_fix{
  content: "";
  display: block;
  clear: both;
}

$font-title: 'Gilroy';

h2{
  font-family: $font-title;
  font-size: 80px;
  letter-spacing: -1px;
  line-height: 0.9;
  font-weight: bold;
  overflow: hidden;
  .text-black{
    color: #1d1d1d;
    display: block;
  }
  .text-white{
    display: block;
    color: rgba(255, 255, 255, 0);
    -webkit-text-stroke: 1px #1d1d1d;
    text-stroke: 1px #1d1d1d;
  }
  span + span{
    margin-top: 5px;
  }
}

h3{
  font-family: $font-title;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
}

h5{
  font-family: $font-title;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
}

h6{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #242424;
}

p{
  font-family: $font-title;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #1d1d1d;
}

.button-black{
  display: block;
  clear: both;
  font-family: $font-title;
  background-color: #1d1d1d;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding: 21px 63px 19px 24px;
  position: relative;
  width: 223px;
  @include prefixonprop(transition, width .6s 0s cubic-bezier(.25,.1, 0,1), transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
  &:focus{
    color: #ffffff;
  }
  &:after{
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(../img/arrow-right.svg) no-repeat center center;
    @include prefixonprop(transform, rotate(-90deg));
    position: absolute;
    right: 24px;
    top: 18px;
    @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
  }
  &:hover{
    text-decoration: none;
    color: #ffffff;
    width: 243px;
    transition-delay: 0s!important;
    @include prefixonprop(transition, width .6s cubic-bezier(.25,.1, 0,1));
    transition-origin: left;
    &:after{
      right: 20px;
      @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
    }
  }
}

.bg{
  &.next{
    z-index: -1;
  }
   &.selected{
    z-index: 0;
    transform: scaleX(0);
    transition: transform 1.2s cubic-bezier(.25,.1, 0,1);
  }
}

.bg-change-color-G{
  display: block;
  position: absolute;
  z-index: -2;
  right: 0;
  width: 44vw;
  height: 747px;
  opacity: 1;
  background-color: #F1F1F1;
  transform-origin: left; 
}
.bg-change-color-Y{
  display: block;
  position: absolute;
  z-index: -4;
  right: 0;
  width: 44vw;
  height: 747px;
  background-color: #FDF6EA;
  opacity: 1;
  transform-origin: left; 
}
.bg-change-color-R{
  display: block;
  position: absolute;
  z-index: -3;
  right: 0;
  width: 44vw;
  height: 747px;
  background-color: #FEE8EF;
  opacity: 1;  
  transform-origin: left; 
}

/*********** 
   HEADER
************/
#wrapper_header {
  background-color: transparent;
  position: absolute;
  top: 12px;
  @include block;
  z-index: 22;
  #header{
    @include block;
    .container{
      padding: 36px 0;
      &:after {
        @include clear_fix;
      }
      h1{
        text-indent: -9999px;
        height: 0;
      }
      .logo{
        display: block;
        width: 174px;
        height: auto;
        float: left;
      }
      .link-right{
        float: right;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: bold;
        color: #1d1d1d;
        vertical-align: middle;
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        opacity: 1;
        padding: 0px 0 5px;
        cursor: pointer;
        &:before{
          content: "";
          width: 32px;
          height: 32px;
          display: inline-block;
          background: url(../img/icon-tel.svg) no-repeat center top;
          vertical-align: middle;
          margin-left: 16px;
          float: right;
          margin-top: -5px;
        }
        &:after{
          display: block;
          content: "";
          width: 0;
          height: 2px;
          background-color: #1d1d1d;
          @include prefixonprop(transition, width 0.6s cubic-bezier(.25,.1, 0,1));
        }
        &:hover{
          text-decoration: none;
          &:after{
            width: 95px;
            @include prefixonprop(transition, width 0.6s cubic-bezier(.25,.1, 0,1));
          }
        }
      }
    }
  }
}
/***************
  END HEADER
****************/
.hero {
  display: block;
  .container {
    display: block;
    padding: 183px 0 80px;
    @include flex-box;
    align-items: flex-top;
    .col-12{
      padding: 0;
      position: relative;
      z-index: 1;
      h2{
        max-width: 473px;
        span{
          opacity: 1;
          @include prefixonprop(transform, translateY(0));
          @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        }
        @for $i from 1 through 2{ 
          .item-#{$i}{
              transition-delay: 0.1s + ($i / 79);
          }
        }
      }
    }
    .col-img {
      z-index: 0;
      position: relative;
      min-height: 630px;
      @include prefixonprop(order, 2);
      .card-container{
        background-color: #ffffff;
        position: absolute;
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        transition-delay: 1.8s;
        z-index: 3;
        border: 1px solid #EFEFEF;
        .img-container{
          display: block;
          padding: 24px;
          width: 255px;
          height: 255px;
          overflow: hidden;
          position: relative;  
          >div{
            position: absolute;
            z-index: 1;
            left: 24px;
            width: 207px;
            height: 207px;
            transform-origin: left;
            overflow: hidden;
            img{
              width: 207px;
              height: 207px;
              display: inline-block;
              position: relative;
            }
            &.image-1{
              z-index: -1;
            }
            &.image-2{
              z-index: 0;
            }
            &.image-3{
              z-index: 1;
            }
            &.next{
              z-index: 2;
            }
            &.selected{
              z-index: 3;
              width: 0;
              @include prefixonprop(transition, width 1.2s cubic-bezier(.25,.1, 0,1));
            }
          }
        }
        h6{
          padding: 0 16px 16px;
           transition-delay: 0s;
        }
        .card-title{
          height: 36px;
          overflow: hidden;
          h6{
            position: absolute;
            left: 0;
            z-index: 0;
            opacity: 0;

            &.selected{
              z-index: 2;
              transform: translateY(0);
              opacity: 1;
              transition: transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.5s ease-out;
            }    
          }
        }
        .footer-card{
          height: 38px;
          position: relative;
          overflow: hidden;
          display: block;
          @include flex_box;
          justify-content: space-between;
          border-top: 1px solid #e4e4e4;
          h6{
            padding-top: 8px;
            padding-bottom: 8px;
            position: absolute;
            left: 0;
            width: 50%;
            z-index: 0;
            opacity: 0;
            &.selected{
              z-index: 2;
              transform: translateY(0);
              opacity: 1;
              transition: transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.5s ease-out;
            }  
          }
          .price{
            position: relative;
            h6{
              left: 0;
            }
          }
          .likes{
            width: 50%;
            position: relative;
            &:before{
              content: "";
              width: 24px;
              height: 24px;
              display: inline-block;
              background: url(../img/icon-heart.svg) no-repeat center center;
              position: absolute;
              top: 7px;
              right: 33px;
            }
            h6{
              right: 0;
              left: auto;
              text-align: right;
            }
          }
        }
      }
      .block-top{
        bottom: 0;
        right: 22%;
        z-index: 3;
      }
      .block-right{
        bottom: 0%;
        right: -29%;
        z-index: 5;
        transition-delay: 1.9s;
        .likes{
          &:before{
            right: 40px!important;
          }
        }
      }
      .full-img-product{
        position: relative;
        left: 0px;
        width: 350px;
        height: 454px;
        top: 3%;
        overflow: hidden;
        div{
          position: absolute;
          width: 350px;
          height: 100%;
          overflow: hidden;
          transform-origin: left;
           img{
            display: block;
            width: auto;
            height: 454px;
            position: relative;     
            transform-origin: center; 
            transform: scale(1.1,1.1);
            transition-delay: 1s;
            z-index: 1;
          }
          &:first-child{
            z-index: 1;
          }
          &:nth-child(2){
            z-index: 0;
          }
          &:last-child{
            z-index: -1;
          }
          &.next{
            z-index: 2;
            img{
              transform: scale(1,1);
              transition: transform 1.7s cubic-bezier(.25,.1, 0,1);
            }
          }
          &.selected{
            z-index: 3;
            width: 0;
            transition: width 1.2s cubic-bezier(.25,.1, 0,1);  
          }
          div:not(.selected) img, {
            transform: scale(1.1,1.1);
            
          }
        }     
      }
    }
    .content-left {
      @include prefixonprop(order, 1);
      p{
        margin-top: 23px;
        max-width: 475px;
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        transition-delay: 0.44s;
      }
    }
    .list{
      display: block;
      margin-top: 24px;
      li{
        display: block;
        position: relative;
        opacity: 1;
        @include prefixonprop(transform, translateY(0));
        @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 1.2s ease-out);
        a{
          display: block;
          font-family: $font-title;
          font-size: 18px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: #1d1d1d;
          padding-left: 40px;
        }
        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          background-position: center;
          background-size: 24px auto;
          background-repeat: no-repeat;
        }
        &:first-child:before{
          background-image: url(../img/fulfillment.svg);
        }
        &:nth-child(2):before{
          background-image: url(../img/multichannels.svg);
        }
        &:last-child:before{
          background-image: url(../img/growth.svg);
        }
      }
      li + li {margin-top: 16px;}
      @for $i from 1 through 3{ 
        .item-#{$i}{
          transition-delay: 0.7s + ($i / 8);
        }
      }
    }
    .button-black{
      margin-top: 40px;
      width: 251px;
      &:hover{
        width: 267px;
      }
    }
  }
}

/***************
   END HERO
****************/
/***************
   END 
****************/
.colums-module {
  display: block;
  .container {
    padding: 60px 0;
    .row{
      display: block;
      @include flex_box;
      flex-direction: row;
      align-items: center;   
      .col-12{
        h2{
          font-size: 66px;
          line-height: 1.09;
        }
        img{
          max-width: 255px;
          height: auto;
        }       
        p{
          max-width: 445px;
        }
        h2 + p{margin-top: 32px;}
        p + a{
          margin-top: 40px;
        }
        .button-black:hover{
          width: 240px;
        }
      }
      .col-img{
        @include flex_box;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        .img-container{
          max-width: 255px;
          height: 284px;
          margin: 15px 0;
          width: 44.737%;
          position: relative;
          img{
            width: 255px;
            height: 284px;
            display: inline-block;
            position: relative;
          }
          >div{
            display: block;
            width: 255px;
            height: 284px;
            position: absolute;
            overflow: hidden;
            transform-origin: left;
            z-index: 1;
            &:first-child{
              z-index: 1;
            }
            &:nth-child(2){
              z-index: 0;
            }
            &:last-child{
              z-index: -1;
            }
            &.next{
              z-index: 2;
            }
            &.selected{
              z-index: 3;
              width: 0;
              transform-origin: left;
              @include prefixonprop(transition, width 1.2s cubic-bezier(.25,.1, 0,1));
            }
          }
          &.card-1{
            position: absolute;
            left: 4%;
            top: 50%; 
            @include prefixonprop(transform, translateY(-50%));
          }
        }
        &.animBlock[data-position="up"]{
          .bg-color{
           @include prefixonprop(transform, scaleY(0) translateX(-50%));
          }
          .card-2, .card-3{
            opacity: 0;
            @include prefixonprop(transform, translateY(20%));
          }
          .card-1{
            opacity: 0;
            @include prefixonprop(transform, translateY(-30%));
          }
        }
        &.animBlock[data-position="up"].viewed{
          .bg-color{
           @include prefixonprop(transform, scaleY(1) translateX(-50%));
           @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1));
          }
          .card-2, .card-3{
            @include prefixonprop(transform, translateY(0));
            @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
            opacity: 1;
          }
          .card-1{
            @include prefixonprop(transform, translateY(-50%));
            @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out);
            opacity: 1;
          }
          @for $i from 1 through 4{ 
            .card-#{$i}{
                transition-delay: 0.1s + ($i / 8);
            }
          }
        }
      }
    }
  }
}

#butler-section{
  .container{
    padding: 200px 0 100px;
    text-align: left;
    .col-md-6{
      position: relative;
      >p{
        margin-top: 24px;
      }
      >img{
        width: 353px;
        height: auto;
        display: block;
        position: absolute;
        bottom: -99px;
        left: -60px;
      }
    }
    .new-list{
      display: block;
      margin-top: 24px;
      text-align: left;
      li{
        display: block;
        position: relative;
        p{
          display: block;
          font-size: 18px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: normal;
          color: #1d1d1d;
          padding-left: 40px;
        }
        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          background-position: center;
          background-size: 24px auto;
          background-repeat: no-repeat;
        }
        &:first-child:before{
          background-image: url(../img/multichannels.svg);
        }
        &:nth-child(2):before{
          background-image: url(../img/2-hours.svg);
        }
        &:nth-child(3):before{
          background-image: url(../img/fulfillment.svg);
        }
        &:last-child:before{
          background-image: url(../img/inventory.svg);
        }
      }
      li + li {margin-top: 16px;}
    }
    .container-video{
      display: block;
      margin: 10px auto 0;
      max-width: 502px;
      position: relative;
      .btn_video{
        position: absolute;
        left: 50%;
        top: 50%;
        @include prefixonprop(transform, translate(-50%,-50%));
        width: 217px;
        cursor: pointer;
        margin-left: 0;
        transition-delay: 0s;
        &:after{
          background-image: url(../img/icon-play.svg);
          transform: rotate(0deg);
          @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
        }
        &:hover{
          width: 225px;
          transition-delay: 0s!important;
          @include prefixonprop(transition, width .6s cubic-bezier(.25,.1, 0,1));
          transition-origin: left;
          &:after{
            right: 20px;
            @include prefixonprop(transition, right .6s cubic-bezier(.25,.1, 0,1));
          }       
        }
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    #col-text{
      padding-left: 8%;
    }
  }
}
/***************
   END 
****************/
#fulfillment{
  .container{
    position: relative;
    .row{
      position: relative;
      .col-12{  
        h2{
          overflow: hidden;
        }
        h3{
          margin-top: 58px;
        }
        p{
          margin-top: 24px;
          max-width: 374px;
        }
        h5{
          font-family: $font-title;
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.45;
          letter-spacing: normal;
          color: #1d1d1d;
          max-width: 389px;
          padding-top: 8px;
        }
        .button-black{
          margin-top: 32px;
          &:hover{
            @include prefixonprop(transition, width 0.6s 0s cubic-bezier(.25,.1, 0,1)!important);
          }
        }
        .bg-white{
          width: 106%;
          padding: 48px 22px 52px;
          border-radius: 2px;
          border: solid 1px #efefef;
          background-color: #ffffff;
          text-align: center;
          position: absolute;
          right: -8%;
          top: 50%;
          @include prefixonprop(transform, translateY(-43%));
          h3{
            margin-top: 0;
          }
          .container-services{
            width: 100%;
            @include flex_box;
            justify-content: space-evenly;
            align-content: center;
            align-items: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            .service{
              display: block;
              max-width: 200px;
              width: 100%;
              margin-top: 32px;
              p{
                font-family: $font-title;
                font-size: 16px;
                color: #1d1d1d;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
              }
              &:before{
                content: "";
                height: 56px;
                width: 56px;
                display: block;
                margin: 0 auto 14px;
              }
              &.item-1:before{
                background: url(../img/icon-onboarding.svg) no-repeat center center;
              }
              &.item-2:before{
                background: url(../img/icon-seller.svg) no-repeat center center;
              }
              &.item-3:before{
                background: url(../img/icon-inventory.svg) no-repeat center center;
              }
              &.item-4:before{
                background: url(../img/icon-packaging.svg) no-repeat center center;
              }
              &.item-5:before{
                background: url(../img/icon-options.svg) no-repeat center center;
              }
            }
          }
        }
      }
    }
    .bg-color{
      display: block;
      height: 543px;
      width: 50vw;
      background-color: rgba(57, 233, 151, 0.2);
      margin: 0 auto;
      z-index: -1;
      position: absolute;
      left: 50%;
      bottom: 32px;
      transform-origin: top;
    }
  }
}
#customer-insights{
  .container{
    padding: 100px 0 60px;
    .row{
      .col-md-6{  
        h2{
          font-size: 66px;
          line-height: 1.09;
        }  
        min-height: auto!important; 
        p{
          max-width: 440px;
          position: relative;
          z-index: 4;
        }
        &.col-img{
           img:first-child{
            max-width: 488px;
            width: 100%;
            height: auto;
            display: block;
            position: relative;
            z-index: 1;
            margin-right: 0;
            margin-left: auto;
          }
          img:nth-child(2){
            max-width: 214px;
            width: 44%;
            height: auto;
            display: block;
            position: relative;
            z-index: 2;
            padding: 16px;
            background-color: #ffffff;
            top: -80px;
            left: -60px;
          }
          img:last-child{
            max-width: 562px;
            width: 100%;
            height: auto;
            display: block;
            position: absolute;
            z-index: 0;
            right: 30px;
            bottom: -20px;
          }
        }
        .button-black{
          width: 252px;
          margin-top: 48px;
          transition-delay: 0s;
          &:hover{
            width: 267px;
            transition-delay: 0s!important;
          }
        }
        .logos{
          display: block;
          width: 100%;
          @include flex_box;
          align-content: center;
          align-items: center;
          margin-top: 42px;
          li{
            display: inline-block;
            width: 92px;
            img{
              max-width: 92px;
              height: auto;
              vertical-align: middle;

            }
          }
          li:first-child{
            img{
              margin-top: -4px;
            }
          }
          li:nth-child(2){
            img{
              margin-top: 11px;
            }
          }
          li:nth-child(4){
            img{
              max-width: 50px;
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
/***************
  
****************/
/***************
    MEDIA
****************/
#media-container{
  @include block;
  .container{
    margin: 0px auto 140px;
    text-align: center;
    h5{
      i{
        opacity: 0.5;
        font-style: italic;
        font-weight: normal;
      }
    }
    .container-logos{
      @include flex_box;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      a{
        margin: 40px 0;
        max-width: 160px;
        img{
          max-width: 160px;
        }
      }
    }
  }
}
/***************
   END MEDIA
****************/
/***************
   CONTACT
****************/
#contact{
  margin-bottom: 80px;
  .container{
    position: relative;
    padding: 0;
    .bg-black{
      display: block;
      background-color: #1d1d1d;
      max-width: 930px;
      height: 100%;
      width: 70%;
      top: 0;
      position: absolute;
      right: -13.3%;
      z-index: 1;
    }
    .col-12:last-child{
      min-height: 687px;
      .container-form{
        padding: 80px 0 80px;
        text-align: left;
        position: relative;
        z-index: 2;
        @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
        &.hidden{
          @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
          z-index: 0;
          opacity: 0;
        }
        p{
          max-width: 100%;
        }
      }
      .msj-submit{
        position: absolute;
        text-align: left;
        z-index: 0;
        opacity: 0;
        padding: 65px 0 80px;
        left: 0;
        top: 0;
        @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
        h2{
          .text-black{
            color: #ffffff;
            display: inline-block;
            margin-left: 0;
          }
          .text-white{
            -webkit-text-stroke: 1px #ffffff;
            text-stroke: 1px #ffffff;
            margin-top: 5px;
            display: inline-block;
            margin-left: 16px;
          }
        }
        p{
          color: #ffffff;
          margin-top: 24px;
          max-width: 100%;
        }
        &.show{
          @include prefixonprop(transition, opacity 0.4s ease-out, z-index 0.4s ease-out);
          opacity: 1;
          z-index: 4;
        }
      }
      h2{
        font-size: 80px;
        .text-black{
          color: #ffffff;
          display: inline-block;
        }
        .text-white{
          -webkit-text-stroke: 1px #ffffff;
          text-stroke: 1px #ffffff;
          display: inline-block;
          margin-left: 19px;
        }
      }
      p{
        color: #ffffff;
        margin-top: 24px;
      }
      form{
        display: block;
        max-width: 445px;
        .field{
          width: 100%;
          margin-top: 42px;
          position: relative;
          input::-webkit-input-placeholder{color: rgba(255,255,255,0.4);}
          input:-moz-placeholder{color: rgba(255,255,255,0.4);}
          input::-moz-placeholder{color: rgba(255,255,255,0.4);}
          input:-ms-input-placeholder{color: rgba(255,255,255,0.4);}
          input.error::-webkit-input-placeholder{color: #ff5e4d;}
          input.error:-moz-placeholder{color: #ff5e4d;}
          input.error::-moz-placeholder{color: #ff5e4d;}
          input.error:-ms-input-placeholder{color: #ff5e4d;}
          input{
            @include prefixonprop(appearance, none);
            background-color: rgba(255,255,255,0.1);
            width: 100%;
            height: 56px;
            border: 0;
            box-shadow: none;
            color: #ffffff;
            padding: 2px 24px 0;
            font-family: $font-title;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            :placeholder{
              color: rgba(255,255,255,0.8);
            }
            &.error{
              background-color: rgba(255,94,77,0.1);
            }
          }
          .button-black{
            width: 259px;
            background-color: #ffffff;
            border: 0;
            color: #1b1b1b;
            margin-top: 10px;
            &:after{
              background-image: url(../img/icon-arrow-black.svg);
              @include prefixonprop(transform, rotate(360deg));
            }
            &:hover{
              width: 267px;
              @include prefixonprop(transition, width 0.6s 0s cubic-bezier(.25,.1, 0,1)!important);
            }
          }
          label.error{
            font-size: 14px;
            font-weight: normal;
            font-family: $font-title;
            color: #ff5e4d;
            position: absolute;
            top: 61px;
            left: 0;
          }
        }
      }
     
    }
    .col-img{
      min-height: auto;
      img{
        width: 100%;
        height: auto;
        max-width: 540px;
        z-index: -1;
        position: relative;
        top: -70px;
      }
    }
  }
}

#grow{
  .container{
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
    .row{
      .col-12{
        width: 100%;
        h2{
          span{
            display: inline-block;
          }
          span + span {
            margin-left: 16px;
          }
        }
        text-align: center;
        p{
          max-width: 730px;
          margin: 40px auto 0;
        }
        .button-black{
          width: 259px;
          margin: 48px auto 0;
          @include prefixonprop(transition, transform 1.2s cubic-bezier(.25,.1, 0,1), opacity 0.4s ease-out, width 0.6s 0s cubic-bezier(.25,.1, 0,1));
          &:hover{
            width: 267px;
            transition-delay: 0s;
            transform-origin: left;
            @include prefixonprop(transition, width 0.6s 0s cubic-bezier(.25,.1, 0,1)!important);
          }
        }
        .img-desktop{
          max-width: 734px;
          width: 100%;
          height: auto;
          margin: 0 auto 72px;
        }
      }
    }
  }
}
/***************
  END FOOTER
****************/
footer{
  @include block;
  background-color: #1d1d1d;
  .container{
    text-align: left;
    padding: 48px 0 80px;
    &:after{
      @include clear_fix;
    }
    .row{
      @include flex_box;
      justify-content: stretch;
    }
    .col-center{
      width: 20%;
    }
    .col-left{
      width: 20%;
    }
    .footer-block{
      @include flex_box;
      flex-direction: column;
      height: 100%;
      img{
        float: left;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        opacity: 1;
        margin: 0 24px 0 0;
      }
      >p{
        display: inline-block;
        font-family: $font-title;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        color: #e4e4e4;
        padding-top: 16px;
        max-width: 390px;
      }
      .copy{
        align-self: end;
        position: absolute;
        bottom: 38px;
        color: #898989;
      }
      .link-title{
        font-family: $font-title;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2px;
        color: #ffffff;
        text-transform: uppercase;
        margin-top: 8px;
      }
      .link{
        display: block;
        margin-top: 32px;
        li{
          display: block;
          a{
            font-family: $font-title;
            display: inline-block;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.467;
            letter-spacing: normal;
            color: #e4e4e4;
            cursor: pointer;
          }
        }
        li + li {
          margin-top: 14px;
        }
      }
      .info-list{
        li{
          position: relative;
          a{
            padding-left: 32px;
            display: inline-block;
          }
          &:first-child:before{
            content: "";
            width: 24px;
            height: 22px;
            background: url('../img/email.svg') no-repeat center;
            background-size: 16px auto;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
          &:last-child:before{
            content: "";
            width: 24px;
            height: 20px;
            background: url('../img/calls.svg') no-repeat center;
            background-size: 16px auto;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      .social{
        float: right;
        display: block;
        vertical-align: middle;
        margin-top: 10px;
        li{
          display: inline-block;
          a{
            display: block;
            outline: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
            @include prefixonprop(transition, opacity 0.3s ease-out);
            &.twitter{
              background: url(../img/twitter.svg) no-repeat center;
            }
            &.in{
              background: url(../img/linked.svg) no-repeat center;
            }
            &.face{
              background: url(../img/facebook.svg) no-repeat center;
            }
            &:hover{
              opacity: 0.24;
              @include prefixonprop(transition, opacity 0.3s ease-out);
            }
          }
        }
        li + li {margin-left: 10px;}
      }
    }
  }
}


/***************
     MODAL
****************/
.modal-backdrop.in{
  opacity: 0.67;
}
#videoModal{
  @include prefixonprop(transition, opacity .3s ease-out);
  transition-delay: .1s;
  &.in .modal-dialog,
  &.fade .modal-dialog{
    left: 50%;
    top: 50%;
    @include prefixonprop(transform, translate(-50%, -50%));
  }
  .modal-dialog{
    width: auto;
    max-width: 850px;
    width: 100%;
    margin:  0;
    .modal-content{
      position: relative;
      background-color: #000000;
      border: 0;
      @include prefixonprop(border-radius, 0);
      @include prefixonprop(box-shadow, none);
      .close{
        position: absolute;
        top: -24px;
        right: -30px;
        font-size: 40px;
        font-weight: 300;
        opacity: 1;
        z-index: 4;
        color: #ffffff;
        text-shadow: none;
      }
    }
  }
}
/***************
  END  MODAL
****************/

/***************
   RESPONSIVE
****************/
@media only screen and (max-width: 1024px) {
  #fulfillment{
    .container{
      .row{
        .col-12{  
          .bg-white{
            width: 89%;
            right: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 980px) {
  #fulfillment{
    .container{
      .bg-color{display: none;}
      .row{
        flex-direction: column;
        .col-12{  
          width: 100%;
          display: block;
          float: none;
          p, h5{
            max-width: 100%;
          }
          .bg-white{
            width: 89%;
            position: static;
            display: block;
            margin: auto;
            @include prefixonprop(transform, translate(0));
          }
        }
        .col-img{
          margin-top: 30px;
          background-color: rgba(57, 233, 151, 0.2);
          padding: 40px 15px 80px;
        }
      }
    }
  }

   #butler-section .container{text-align: left; padding-top: 90px; padding-bottom: 30px;}
  #butler-section .container .new-list{max-width: 472px;}
  #butler-section .container #col-text{margin-top: 120px; padding-left: 15px;}
  #butler-section .container .col-md-6 > img{left: auto; right: 2%;}

  footer{
    .container{
      .row{
        display: block;
        .col-contact{
          display: block;
          width: 100%;
        }
      }
      .col-center{
        width: 33.3333%;
      }
      .col-left{
        width: 33.3333%;
      }
      .footer-block{
        padding-top: 30px;
        padding-bottom: 30px;
        .copy{
          position: static;
          padding-top: 40px;
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .row{flex-direction: column!important;}
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 860px;}

  .hero .container .col-12{min-height: 509px;width: 100%!important;}
  .hero .container .col-12 h2{max-width: 600px;}
  .hero .container .col-img .block-top{left: 0%; bottom: 27%;right: auto;}
  .hero .container .col-img .block-right{left: 41%; bottom: 0;right: auto; top: auto;}
  .hero .container .col-img .full-img-product{left: 47%; top: -200px;}

  .colums-module .container .row .col-12:first-child{width: 100%; position: static;}
  .colums-module .container .row .col-12:last-child{min-width: auto;}

  #media-container .container{max-width: 560px; justify-content: center; margin: 0 auto 100px;}
  #media-container .container h5, #media-container .container a{margin: 20px 30px;}
  #media-container .container .container-logos a{margin: 8px 0;}

  #fulfillment .container {padding-bottom: 31px;}
  #fulfillment .container .row .col-img{min-height: auto; min-width: 100%;}
  #fulfillment .container .row .col-12 .container-cards{margin-top: 60px;}
  #fulfillment .container .row .col-12 .container-cards .card{margin-top: 30px;}
  #fulfillment .container .bg-color{height: 470px; width: 80vw; left: 20%; bottom: -1%;}
  #fulfillment .container .row .col-12 p{max-width: 100%;}
  
  #grow .container{text-align: left;}
  #grow .container h2 span + span{margin-left: 0;}

  #customer-insights .container{padding-top: 60px; padding-bottom: 200px;}
  #customer-insights .container .row {position: relative;}
  #customer-insights .container .row .col-md-6{min-width: 100%;min-height: auto;}
  #customer-insights .container .row .col-img{margin-top: 32px;}
  #customer-insights .container .row .col-md-6 .container-animated{width: 100%;max-width: 450px; position: relative;}
  #customer-insights .container .row .col-md-6 .container-animated img{min-width: auto;}
  #customer-insights .container .row .col-md-6 p{max-width: 100%;}
  #customer-insights .container .row .col-md-6 .container-animated img:first-child {max-width: 541px;width: 85%;}
  #customer-insights .container .row .col-md-6 .container-animated img:nth-child(2){max-width: 145px;position: absolute;top: 39%;left: 37px;width: 43%;}
  #customer-insights .container .row .col-md-6 .container-animated img:last-child{max-width: 562px;position: relative;right: 6px;bottom: 12px;margin-top: 0;}
  #customer-insights .container .row .col-md-6 .logos{justify-content: center; flex-wrap: wrap; align-items: center;position: absolute;left: 50%;@include prefixonprop(transform, translateX(-50%));bottom: -43px;}

  #contact{overflow: hidden;}
  #contact .container .col-12 .container-img{padding-top: 332px;}
  #contact .container .col-12 img{top: 0; left: -15px; position: absolute;}
  #contact .container .bg-black{max-width: auto;height: 100%;width: calc(100% - 15px);right: 0;top: 332px;}
  #contact .container .col-12:last-child .container-form ,#contact .container .col-12:last-child .msj-submit{padding: 65px 32px 80px;}
  #contact .container .col-12:last-child {min-width: 100%;}

  #videoModal .modal-dialog .modal-content iframe{margin-top: 65px; height: calc(100vh - 65px);}

  #fulfillment  .container .row .col-12 .button-black,
  #customer-insights .container .row .col-12 .button-black{position: absolute; bottom: -28px; left: 50%;@include prefixonprop(transform, translateX(-50%));z-index: 4;}
  #customer-insights .container .row .col-12 .button-black{bottom: -133px; }

  footer{
    .container{
      text-align: center;
      .row{
        display: block;
        .col-contact{
          width: 33.3333%;
          float: left;
        }
      }
      .col-center{
        width: 33.3333%;
        float: left;
      }
      .col-left{
        width: 33.3333%;
        float: left;
      }
      .footer-block{
        img{
          margin: 0 auto;
        }
        p{
          max-width: 640;
          margin: 0 auto;
        }
        .copy{
          padding-top: 20px;
        }
        .info-list li {
          &:first-child:before, &:last-child:before{
            position: static;
            text-align: center;
            vertical-align: middle;
            height: 15px;
          }
          a{
            padding-left: 8px;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

}
@media only screen and (max-width: 640px) {
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 920px;}

  #wrapper_header #header .container .logo img{max-width: 140px;}

  .hero .container .content-left p{display: none;}
  .hero .container{padding-top: 135px; padding-bottom: 60px;}
  .hero .container .col-12{min-height: auto;}
  .hero .container .col-img{min-height: auto;height: auto;}
  .hero .container .col-img .full-img-product{width: 259px;height: 291px;left: 0; margin-top: 32px; top: 0;}
  .hero .container .col-img .block-top{width: 158px; height: 191px;left: 0;top: 62px; overflow: hidden; display: none;}
  .hero .container .col-img .block-right{width: 158px; height: 191px;right: 0;left: auto;top: 184px; overflow: hidden;}
  .hero .container .col-img .card-container .img-container {width: 158px;height: 127px; padding: 8px 24px;}
  .hero .container .col-img .card-container .img-container > div,
  .hero .container .col-img .card-container .img-container > div img{width: 110px;height: 110px;}
  .hero .container .col-img .card-container .card-title{overflow: hidden;height: 24px;}
  .hero .container .col-img .card-container .card-title h6{font-size: 12px;padding: 0 8px 10px;line-height: normal;white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
    width: 152px;
    overflow: hidden;
    table-layout: fixed;
    display: table-cell;
  }
  .hero .container .col-img .card-container h6{padding: 0 8px 8px;}
  .hero .container .col-img .full-img-product div img{width: 259px;height: auto;}

  .colums-module .container .row .col-12 h2 + p,#grow .container .row .col-12 p{margin-top: 24px;}

  #fulfillment .container .row .col-12 h3{margin-top: 35px;}
 
  #brands-container .container{ max-width: 360px; margin-top: 80px; margin-bottom: 80px;}
  #media-container .container{ max-width: 360px; margin-top: 0px; margin-bottom: 40px;}

  .colums-module .container .row .col-md-6 p + a{margin-top: 40px;}
  #fulfillment .container .row .col-12 .container-cards .text-or{margin-top: 30px;}
  #fulfillment .container .row .col-12 .container-cards{flex-direction: column; margin-top: 0;}
  #fulfillment .container .row .col-img{min-height: auto; min-width: 100%;}
  #fulfillment .container .bg-color{ min-height: 870px; left: 0; width: 100vw;}
  .colums-module .container .row .col-img .card-2,
  .colums-module .container .row .col-img .card-1{width: 255px;height: 284px;margin: 0;}
  .colums-module .container .row .col-img .card-2{top: 12px; z-index: 0;}

  #grow .container{padding: 100px 15px 50px;text-align: left;}
  #grow .container .row .col-12{text-align: left; }
  #grow .container .row .col-12 h2 span{display: block; margin-left: 0!important;}
  #grow .container .row .col-12 .button-black{margin:32px 0 0;}
  #grow .container .row .col-12 p{margin-left: 0;}

  #customer-insights .container .row .col-md-6 img:nth-child(2){left: 0;}
  #customer-insights .container .row .col-md-6 img:last-child{right: 0;}
  #customer-insights .container .row .col-12 .button-black{bottom: -216px;@include prefixonprop(transform, translateX(-50%));left: 50%;}
  #customer-insights .container .row .col-md-6 .container-animated img:nth-child(2){left: -14px;}
  #customer-insights .container .row .col-md-6 .logos{max-width: 450px; margin: 32px auto 0;bottom: -143px;}
  #customer-insights .container .row .col-md-6 .logos li{width: 33.3333%; margin-bottom: 20px;}
  #customer-insights .container .row .col-md-6 .logos li img{display: block; margin: auto; width: 80%;}

  #contact .container .col-12:last-child .container-form, #contact .container .col-12:last-child .msj-submit{padding: 50px 32px;}
  #contact .container .col-12:last-child h2 .text-white{margin-left: 0;}
}

@media only screen and (max-width: 480px) {
  h2{
    font-size: 50px!important;
    line-height: 1.04!important;
    .text-white{
      margin-top: 0;
    }
  }
  p{
    font-size: 17px;
    line-height: 1.65;
  }
  .bg-change-color-R, .bg-change-color-Y, .bg-change-color-G{width: 73vw; height: 761px;}

  #wrapper_header #header .container .link-right:after{margin-left: 5px;}

  .colums-module .container{padding: 70px 0 30px;}
  .colums-module .container .row .col-img .card-1{left: -27%; top: 118px;}

  #fulfillment .container .row .col-img{min-width: 100%;}
  #fulfillment .container .row .col-img .card-1{left: -90px;}
  #fulfillment .container .row .col-md-6 h2 + p{margin-top: 20px;}
  #fulfillment .container .row .col-12 .bg-white{width: 100%;}

  #brands-container .container h5, #media-container .container h5{margin: 20px 20px;}
  #media-container .container a,
  #brands-container .container img{margin: 20px 20px; width: 50%;}

  #contact .container .bg-black{top: 23%;}
  #contact .container .col-12 .container-img{padding-top: 61%;}

  #butler-section .container .container-buttons{flex-direction: column;align-items: flex-start;}
  #butler-section .container .container-buttons{margin-left: 0!important;}
  footer .container{padding: 30px 15px;}
  footer .container > p{
    font-size: 14px;
    max-width: 132px;
    text-align: left;
    margin-top: 5px!important;
    line-height: normal;
  }

  #contact .container .col-12:last-child h2 .text-black{margin-left: 0;}

  footer .container .row .col-contact,footer .container .col-center,footer .container .col-left{width: 100%; float: none;}
 
}


